<template>
  <v-data-table
    :headers="headers"
    :items="projets"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Administration projet
        </v-toolbar-title>
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs}">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Nouveau
            </v-btn>
            <download-excel
              class="mr-3 mb-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
              :data="projets"
              :fields="json_fields"
              type="csv"
            >
              Download CSV
            </download-excel>
            <download-excel
              class="mr-3 mb-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
              :data="projets"
              :fields="json_fields"
              type="xls"
            >
              Download Excel
            </download-excel>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.libelle_projet"
                      label="Libellé Projet"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-select
                      v-model="projetEdit.id_organisations"
                      :items="ong"
                      item-text="libelle_organisation"
                      item-value="id"
                      label="Organisation"
                      single-line
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="projetEdit.date_debut"
                          label="Date de début"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="projetEdit.date_debut"
                        @input="menu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="projetEdit.date_fin"
                          label="Date de fin"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="projetEdit.date_fin"
                        @input="menu2 = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.directeur_technique_nom_prenom"
                      label="Directeur technique(Nom & Prénom)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.directeur_technique_telephone"
                      label="Directeur technique(Téléphone)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.directeur_technique_email"
                      label="Directeur technique(Email)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.responsable_projet_nom_prenom"
                      label="Responsable projet(Nom & Prénom)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.responsable_projet_email"
                      label="Response projet(Email)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.responsable_projet_telephone"
                      label="Responsable projet(Téléphone)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.comptable_nom_prenom"
                      label="Comptable(Nom & Prénom)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="projetEdit.comptable_email"
                      label="Comptable(Email)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="projetEdit.comptable_telephone"
                      label="Comptable(Téléphone)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darke-1"
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darke-1"
                @click="saveProjet"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogAssociate"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Quel type d'association voulez effectuée?
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="text-center">
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <div class="my-2">
                      <v-btn
                        color="success"
                        fab
                        x-large
                        dark
                        @click="openAssociateDI"
                      >
                        <v-icon>mdi-domain</v-icon>
                      </v-btn>
                      <p class="mt-5">
                        Domaine d'intervention
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <div class="my-2">
                      <v-btn
                        color="success"
                        fab
                        x-large
                        dark
                        @click="openAssociateARG"
                      >
                        <v-icon>mdi-book-open-blank-variant</v-icon>
                      </v-btn>
                      <p class="mt-5">
                        AGR
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="closeAssociate"
              >
                Cancel
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        small
        @click="showAssociate(item)"
      >
        mdi-tune-variant
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import profilOng from '@/services/profile-ong.js'
  import adminProjet from '@/services/admin-projets.js'
  export default {
    data () {
      return {
        menu2: false,
        menu: false,
        dialog: false,
        dialogDelete: false,
        dialogAssociate: false,
        json_fields: {
          'Libellé Projet': 'libelle_projet',
          Organisation: 'organisation.libelle_organisation',
          'Date de début': 'date_debut',
          'Date de fin': 'date_fin',
          'Directeur technique(Nom & Prénom)': 'directeur_technique_nom_prenom',
          'Directeur technique(Email)': 'directeur_technique_email',
          'Directeur technique(Téléphone)': 'directeur_technique_telephone',
          'Responsable projet(Nom & Prénom)': 'responsable_projet_nom_prenom',
          'Response projet(Email)': 'responsable_projet_email',
          'Responsable projet(Téléphone)': 'responsable_projet_telephone',
          'Comptable(Nom & Prénom)': 'comptable_nom_prenom',
          'Comptable(Email)': 'comptable_email',
          'Comptable(Téléphone)': 'comptable_telephone',
        },
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Libellé Projet',
            value: 'libelle_projet',
          },
          {
            text: 'Organisation',
            value: 'organisation.libelle_organisation',
          },
          {
            text: 'Date de début',
            value: 'date_debut',
          },
          {
            text: 'Date de fin',
            value: 'date_fin',
          },
          {
            text: 'Directeur technique(Nom & Prénom)',
            value: 'directeur_technique_nom_prenom',
          },
          {
            text: 'Directeur technique(Email)',
            value: 'directeur_technique_email',
          },
          {
            text: 'Directeur technique(Téléphone)',
            value: 'directeur_technique_telephone',
          },
          {
            text: 'Responsable projet(Nom & Prénom)',
            value: 'responsable_projet_nom_prenom',
          },
          {
            text: 'Response projet(Email)',
            value: 'responsable_projet_email',
          },
          {
            text: 'Responsable projet(Téléphone)',
            value: 'responsable_projet_telephone',
          },
          {
            text: 'Comptable(Nom & Prénom)',
            value: 'comptable_nom_prenom',
          },
          {
            text: 'Comptable(Email)',
            value: 'comptable_email',
          },
          {
            text: 'Comptable(Téléphone)',
            value: 'comptable_telephone',
          },
        ],
        projets: [],
        ong: [],
        editedIndex: -1,
        projetEdit: {
          id: '',
          libelle_projet: '',
          id_organisations: '',
          date_debut: '',
          date_fin: '',
          directeur_technique_nom_prenom: '',
          directeur_technique_email: '',
          directeur_technique_telephone: '',
          responsable_projet_nom_prenom: '',
          responsable_projet_email: '',
          responsable_projet_telephone: '',
          comptable_nom_prenom: '',
          comptable_email: '',
          comptable_telephone: '',
        },
        projet: {
          libelle_projet: '',
          id_organisations: '',
          date_debut: '',
          date_fin: '',
          directeur_technique_nom_prenom: '',
          directeur_technique_email: '',
          directeur_technique_telephone: '',
          responsable_projet_nom_prenom: '',
          responsable_projet_email: '',
          responsable_projet_telephone: '',
          comptable_nom_prenom: '',
          comptable_email: '',
          comptable_telephone: '',
        },
      }
    },
    mounted () {
      console.log(this.editedIndex)
      profilOng.getUsersProfile().then(response => {
        this.ong = response.data
      })
      adminProjet.getProjets().then(response => {
        this.projets = response.data
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un projet' : 'Modifier un projet'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      saveProjet () {
        if (this.editedIndex > -1) {
          adminProjet.updateProjet(this.projetEdit).then(response => {
            console.log(response.data)
            adminProjet.getProjets().then(response => {
              this.projets = response.data
            })
          }).catch(err => {
            console.log(err.data)
          })
        } else {
          adminProjet.createProjet(this.projetEdit).then(response => {
            console.log(response.data)
            adminProjet.getProjets().then(response => {
              this.projets = response.data
            })
          }).catch(err => {
            console.log(err.response.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.projetEdit = Object.assign({}, this.profile)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.projets.indexOf(item)
        this.projetEdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.projets.indexOf(item)
        this.projetEdit = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        adminProjet.deleteProjet(this.projetEdit).then(response => {
          adminProjet.getProjets().then(response => {
            this.projets = response.data
          })
        }).catch(err => {
          console.log(err.data)
        })
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.projetEdit = Object.assign({}, this.profile)
          this.editedIndex = -1
        })
      },
      showAssociate (item) {
        this.editedIndex = this.projets.indexOf(item)
        this.projetEdit = Object.assign({}, item)
        this.dialogAssociate = true
      },
      closeAssociate () {
        this.dialogAssociate = false
      },
      openAssociateARG () {
        this.$router.push({
          name: 'association-agr.index',
          params: { project: this.projetEdit.id },
        })
      },
      openAssociateDI () {
        this.$router.push({
          name: 'association-di.index',
          params: { project: this.projetEdit.id },
        })
      },
    },
  }
</script>

<style scoped>

</style>
