import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getProjets () {
    return apiClient.get('api/projet/list-projet')
  },
  createProjet (projetInformations) {
    const formData = new FormData()
    formData.append('libelle_projet', projetInformations.libelle_projet)
    formData.append('id_organisations', projetInformations.id_organisations)
    formData.append('date_debut', projetInformations.date_debut)
    formData.append('date_fin', projetInformations.date_fin)
    formData.append('directeur_technique_nom_prenom', projetInformations.directeur_technique_nom_prenom)
    formData.append('directeur_technique_email', projetInformations.directeur_technique_email)
    formData.append('directeur_technique_telephone', projetInformations.directeur_technique_telephone)
    formData.append('responsable_projet_nom_prenom', projetInformations.responsable_projet_nom_prenom)
    formData.append('responsable_projet_email', projetInformations.responsable_projet_email)
    formData.append('responsable_projet_telephone', projetInformations.responsable_projet_telephone)
    formData.append('comptable_nom_prenom', projetInformations.comptable_nom_prenom)
    formData.append('comptable_email', projetInformations.comptable_email)
    formData.append('comptable_telephone', projetInformations.comptable_telephone)
    return apiClient.post('api/projet/add-projet', formData)
  },

  updateProjet (projetInformations) {
    const formData = new FormData()
    formData.append('libelle_projet', projetInformations.libelle_projet)
    formData.append('id_organisations', projetInformations.id_organisations)
    formData.append('date_debut', projetInformations.date_debut)
    formData.append('date_fin', projetInformations.date_fin)
    formData.append('directeur_technique_nom_prenom', projetInformations.directeur_technique_nom_prenom)
    formData.append('directeur_technique_email', projetInformations.directeur_technique_email)
    formData.append('directeur_technique_telephone', projetInformations.directeur_technique_telephone)
    formData.append('responsable_projet_nom_prenom', projetInformations.responsable_projet_nom_prenom)
    formData.append('responsable_projet_email', projetInformations.responsable_projet_email)
    formData.append('responsable_projet_telephone', projetInformations.responsable_projet_telephone)
    formData.append('comptable_nom_prenom', projetInformations.comptable_nom_prenom)
    formData.append('comptable_email', projetInformations.comptable_email)
    formData.append('comptable_telephone', projetInformations.comptable_telephone)
     return apiClient.post(`api/projet/update-projet/${projetInformations.id}`, formData)
  },

  deleteProjet (projetInformations) {
    return apiClient.post(`api/projet/delete-projet/${projetInformations.id}`)
 },
}
